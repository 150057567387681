// Load jQuery
import $ from 'jquery';
window.$ = window.jQuery = $;

//Load Libraries
require('@accessible360/accessible-slick/slick/slick.min');

//Load Plugins
require('./plugins/animatein');
require('./plugins/jquery.selectbox.min');
require('./plugins/picker');
require('./plugins/picker.date');
require('./plugins/picker.time');
require('./plugins/magnific-popup.min');